import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const MissionPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Mission</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Our Mission</h2>
					<p>To bring people together to build homes, communities, and hope.</p>
					<h2>Our Vision</h2>
					<p>A world where everyone has a decent place to live.</p>
					<h2>Our Welcome</h2>
					<p>
						To include all supporters from all backgrounds, who believe in our mission and vision for
						a world that helps to shelter those in need.
					</p>
					<h2>About Habitat For Humanity International</h2>
					<p>
						Founded in Americus, Georgia, USA, in 1976, Habitat for Humanity today operates
						around the globe and has helped build, renovate and repair more than 600,000 decent,
						affordable houses sheltering more than 3 million people worldwide.
					</p>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default MissionPage

export const Head = () => (
	<Seo
        title="Mission | Habitat For Humanity Northwoods Wisconsin"
    />
)
